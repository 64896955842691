import { Dialog } from "@mui/material";
import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState, useEffect } from "react";
import SEARCH from "../images/icon-search.png";

const SearchComponent = () => {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [result, setResult] = useState([]);

  const tempData = useStaticQuery(graphql`
    query {
      allMdx {
        edges {
          node {
            frontmatter {
              title
              slug
            }
          }
        }
      }
    }
  `);

  const execSearch = () => {
    const value = search.toLowerCase();
    const temp = data.filter((item) => {
      const target = `
        ${item.title.toLowerCase()}
        ${item.slug.toLowerCase()}
        `;
      return target.indexOf(value) !== -1;
    });
    setResult(temp);
  };

  // collect initial data from graphql
  useEffect(() => {
    const temp = [];
    tempData.allMdx.edges.map((e) => {
      temp.push(e.node.frontmatter);
    });
    setData(temp);
  }, []);

  useEffect(() => {
    if (search !== "") {
      execSearch();
    }
  }, [search]);

  const handleLinkClick = (e) => {
    setSearch("");
    setResult([]);
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const inputRef = React.useRef(null);
  const handleOpen = () => {
    setOpen(true);
    inputRef.current?.focus();
  }

  return (
    <>
      <div onClick={handleOpen} className="absolute top-0 right-4">
        <div className="flex justify-end ">
          <div className="flex justify-center items-center h-9 w-9 bg-gray-ddd">
            <img src={SEARCH} alt="search" className="" />
          </div>
        </div>
      </div>

      <Dialog onClose={handleClose} open={open} fullWidth={true}>
        <div>
          <div className="p-4 bg-gray-200">
            <input
              ref={inputRef}
              className={`focus:bg-green-50 rounded border border-2 pl-2 w-48`}
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="min-h-full">
            {result.length > 0 && (
              <div className="p-2">
                <div className="search__result-list">
                  {result.map((item) => (
                    <div className="bg-gray-100 p-1 m-1 rounded">
                      <Link to={`/blog/${item.slug}`} onClick={handleLinkClick}>
                        {item.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SearchComponent;
