import React from 'react'
import HelloImg from '../images/kuroko/hello05.png'

function Profile() {
    return (
        <div className="flex flex-col justify-center items-center text-base">
            <div className="flex bg-white justify-center items-center rounded-full h-24 w-24 overflow-hidden">
                <img className="mt-8" src={HelloImg} alt="kuroko" />
            </div>
            <div>クロココルル</div>
            <p className="text-sm mb-4">当ブログの管理人</p>
            <p>
                日本酒歴10年。<br />
日本酒をただ楽しんでいたある日<br />
「セメダイン臭するね」というやりとりを聞き<br />
なぜセメダイン？と興味が湧き<br />
香り成分や、それを生み出す仕組みを調べ始めたら<br />
さらにハマってしまいました。<br />
                <br />
そして10年が経ち今では<br />
「美味しければいいじゃない」<br />
に落ち着いております。<br />
            </p>
        </div>
    )
}

export default Profile
