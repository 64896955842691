import React from 'react'
import LOGO from '../images/logo.png';
import ICON_FB from '../images/icon-fb.png';
import ICON_TW from '../images/icon-tw.png';
import ICON_IG from '../images/icon-ig.png';
import { Link } from 'gatsby';
import RandomMessage from './random-message';
import SearchComponent from './search';

function Header() {

    return (
        <div className="text-base">
            <div className="h-32 mt-4 flex justify-center items-center relative">
                <Link to="/"><img src={ LOGO } alt="logo" /></Link>
                <SearchComponent />
            </div>
            <div className="bg-gray-999 h-24 flex justify-center items-center shadow">
                <div className="flex text-white">
                    <div className="flex-grow px-4 wrap">
                        <RandomMessage />
                    </div>
                    <div className="flex-shrink-0 flex justify-center items-center">
                        <a href="https://www.facebook.com/takayuki.aoki1">
                            <img className="h-4 pr-4 transition-opacity hover:opacity-70" src={ICON_FB} alt="fb icon" />
                        </a> 
                        <a href="https://twitter.com/kurokokoruru">
                            <img className="h-4 pr-4 transition-opacity hover:opacity-70" src={ICON_TW} alt="tw icon" />
                        </a>
                        <a href="https://www.instagram.com/muroka_namagensyu/">
                            <img className="h-4 pr-4 transition-opacity hover:opacity-70" src={ICON_IG} alt="ig icon" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
