import React from 'react'
import Profile from './profile'

function SideBar() {
    return (
        <div>
            <Profile />
        </div>
    )
}

export default SideBar
