import React, { useState, useEffect } from 'react'

const messages = [
    '美味しければいいじゃない。',
    '感想が他の人と違ってもいいじゃない。',
    '酒の一滴は血の一滴って吸血鬼じゃないんだから。',
    '飲みたい時に飲む。飲みたくない時は無い。　困った。',
    '四季折々のおりがらみ',
    '家の玄関に杉玉つけたい。',
    '良い酒との出会いは、良い店との出会い',
    'アイ ❤️ ジャパニーズサケ',
    'ストライクゾーンがどんどん広がってゆく',
]
const key = Math.floor(Math.random() * messages.length);

const RandomMessage = () => {
    const [ message, setMessage] = useState('');

    useEffect(() => {
        setMessage(messages[key])
    }, [])

    if (message === '') {
        return <></>
    }

    return (
        <div className="animate-fade-in-down ">
            {message}
        </div>
    )
}

export default RandomMessage;