import React from 'react'
import Header from './header';
import Footer from './footer';
import FLOATING from '../images/floating.png';
import { Helmet } from 'react-helmet';
import PickupMenu from './pickup-menu';
import SideBar from './side-bar';
import { animateScroll as scroll } from 'react-scroll';

const Layout = ({ children, data }) => {
    const handleClick = () => {
        scroll.scrollToTop();
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />
                <link rel="canonical" href="https://www.muroka-namagensyu.com" />
                <script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=G-0WY9MZQVCK"
                ></script>
                <script>
                    {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-0WY9MZQVCK');
                `}
                </script>
            </Helmet>
            <div className="m0 p0 flex flex-col min-h-screen">
                <Header />
                <main className="flex-grow">
                    <div className="flex flex-col mt-4 container mx-auto text-white justify-center items-center w-full lg:w-10/12">
                        <PickupMenu />
                        <div className="flex flex-col w-full justify-center items-center lg:items-start lg:grid grid-cols-4">
                            <div className="bg-gray-ddd rounded w-full my-2 text-gray-500 lg:col-span-3">
                                {children}
                            </div>
                            <div className="bg-gray-ddd rounded  lg:w-9/11 mx-4 my-2 p-4 text-gray-500">
                                <SideBar />
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
                <a href="#" className="fixed bottom-16 right-4">
                    <img className="transition-opacity hover:opacity-70" onClick={handleClick} src={FLOATING} />
                </a>
            </div>
        </>
    )
}

export default Layout
