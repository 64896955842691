import React from 'react'
import COPYRIGHT from '../images/copyright.png';
import ICON_FB from '../images/icon-fb.png';
import ICON_TW from '../images/icon-tw.png';
import ICON_IG from '../images/icon-ig.png';


const SnsLink = (name, url, ICON) => {
    return (
        <>
            <a href={url}>
                <img className="h-3 pr-4  transition-opacity hover:opacity-70" src={ICON} alt={name} />
            </a>
            <a className="transition-opacity hover:opacity-70" href={url}>{name}</a>
        </>
    )
}

const FaceBook = () => {
    return SnsLink("Facebook", "https://www.facebook.com/takayuki.aoki1", ICON_FB);
}

const Twitter = () => {
    return SnsLink("Twitter", "https://twitter.com/kurokokoruru", ICON_TW);
}

const Instagram = () => {
    return SnsLink("instagram", "https://www.instagram.com/muroka_namagensyu/", ICON_IG);
}

const Footer = () => {
    return (
        <footer className="bg-gray-999 h-72">
            <div className="my-4 mx-8">
                <h4 className="text-base mb-4">当サイトはリンクフリーです</h4>
                <p className="text-xs">
                    当サイトは管理人の許可なくリンクを張って頂いて問題ございません。
                </p>
                <p className="text-xs">
                    内容をコピー、スクリーンショットや画像のご利用も、
                    該当記事へ「出典」としてリンクを張っていただければ問題ございません。
                </p>
                <p className="text-xs">
                    常識の範囲内でご利用ください。
                </p>
                <div className="flex justify-end mr-8 mt-8">
                    <div className="h-24 flex flex-col text-white text-sm">
                        <div className="flex items-center">
                            <FaceBook />
                        </div>
                        <div className="flex items-center">
                            <Twitter />
                        </div>
                        <div className="flex items-center">
                            <Instagram />
                        </div>
                        <div className="mt-4">
                            <img src={COPYRIGHT} alt="test" />
                        </div>
                    </div>
                </div>

            </div>

        </footer>
    )
}



export default Footer