import React from 'react'
import { navigate } from "gatsby"

const handleClickWhatsSake = () => {
  navigate("/whats-sake")
}

const handleClickLog = () => {
  navigate("/log")
}

const handleClickHowToEnjoy = () => {
  navigate("/howto-enjoy")
}

const handleClickRanking = () => {
  navigate("/ranking")
}

function PickupMenu() {
    return (
        <div className="w-full">
          <div className="flex justify-between lg:hidden">
            <div onClick={handleClickWhatsSake} className="shadow flex justify-center items-center w-full h-16 mr-4 my-2 bg-gray-aaa">日本酒って何？</div>
            <div onClick={handleClickHowToEnjoy} className="shadow flex justify-center items-center w-full h-16 ml-4 my-2 bg-gray-aaa">楽しみ方</div>
          </div>
          <div className="flex justify-between lg:hidden">
            <div onClick={handleClickRanking} className="shadow flex justify-center items-center w-full h-16 mr-4 my-2 bg-gray-aaa">ランキング</div>
            <div onClick={handleClickLog} className="shadow flex justify-center items-center w-full h-16 ml-4 my-2 bg-gray-aaa">お酒記</div>
          </div>
          <div className="hidden lg:grid grid-cols-4">
            <div onClick={handleClickWhatsSake} className="cursor-pointer shadow lg:hover:bg-gray-999 flex justify-center w-9/11 items-center h-16 mr-4 my-2 bg-gray-aaa">
              日本酒って何？
            </div>
            <div onClick={handleClickHowToEnjoy} className="cursor-pointer lg:hover:bg-gray-999 shadow flex justify-center w-9/11 items-center h-16 mr-4 my-2 bg-gray-aaa">楽しみ方</div>
            <div onClick={handleClickRanking} className="cursor-pointer lg:hover:bg-gray-999 shadow flex justify-center w-9/11 items-center h-16 mr-4 my-2 bg-gray-aaa">ランキング</div>
            <div onClick={handleClickLog} className="cursor-pointer lg:hover:bg-gray-999 shadow flex justify-center w-9/11 items-center h-16 mr-4 my-2 bg-gray-aaa">お酒記</div>
          </div>
        </div>
    )
}

export default PickupMenu
